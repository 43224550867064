import z from "zod";

const stringOptionalSchema = z
    .string()
    .transform((str) => (str === "" ? undefined : str))
    .optional();

const configSchema = z.object({
    /*
     * MODE: Enum
     * Specifies the application's runtime environment.
     * Required: Yes | Default: "production"
     * Example (.env): VITE_MODE=production
     */
    MODE: z.enum(["production", "acceptance", "development", "test"]).default("production"),

    /*
     * API_URL: String URL
     * Specifies the URL of the API.
     * Required: Yes
     * Example (.env): VITE_API_URL=http://localhost:3000/api
     */
    API_URL: z.string().url(),

    /*
     * AZURE_AD_AUTH_AUTHORITY: String URL
     * Specifies the authority of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_AUTHORITY=https://login.microsoftonline.com/your-tenant-id
     */
    AZURE_AD_AUTH_AUTHORITY_URL: z.string().url(),

    /*
     * AZURE_AD_AUTH_CLIENT_ID: String UUID
     * Specifies the client ID of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_CLIENT_ID=your-client-id
     */
    AZURE_AD_AUTH_CLIENT_ID: z.string().uuid(),

    /*
     * AZURE_AD_AUTH_SCOPE: String
     * Specifies the allowed scope of access of the Azure AD application.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_SCOPE=your-client-id/all
     */
    AZURE_AD_AUTH_SCOPE: z.string(),

    /*
     * AZURE_AD_AUTH_REDIRECT_URL: String URL
     * Specifies the URL to redirect to after successful login.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_REDIRECT_URL=http://localhost:5173
     */
    AZURE_AD_AUTH_REDIRECT_URL: z.string().url(),

    /*
     * AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL: String URL
     * Specifies the URL to redirect to after logout.
     * Required: Yes
     * Example (.env): VITE_AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL=http://localhost:5173
     */
    AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL: z.string().url(),

    /*
     * IFS_URL: String URL
     * Specifies the URL of the IFS application.
     * Required: Yes
     * Example (.env): VITE_IFS_URL=https://ifs.example.com
     */
    IFS_URL: z.string().url(),

    /*
     * SENTRY_DSN: String URL
     * Data Source Name (DSN) key tells the Sentry SDK where to send events.
     * Required: No
     * Example (.env): VITE_SENTRY_DSN=https://public@sentry.example.com/1
     */
    SENTRY_DSN: z.string().url().or(stringOptionalSchema),
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    MODE: import.meta.env.MODE,
    API_URL: import.meta.env.VITE_API_URL,
    IFS_URL: import.meta.env.VITE_IFS_URL,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    AZURE_AD_AUTH_AUTHORITY_URL: import.meta.env.VITE_AZURE_AD_AUTH_AUTHORITY_URL,
    AZURE_AD_AUTH_CLIENT_ID: import.meta.env.VITE_AZURE_AD_AUTH_CLIENT_ID,
    AZURE_AD_AUTH_SCOPE: import.meta.env.VITE_AZURE_AD_AUTH_SCOPE,
    AZURE_AD_AUTH_REDIRECT_URL: import.meta.env.VITE_AZURE_AD_AUTH_REDIRECT_URL,
    AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL: import.meta.env.VITE_AZURE_AD_AUTH_POST_LOGOUT_REDIRECT_URL,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

export { config };
