import { useAzureAdAuthentication } from "@datamole/wds-azure-ad-auth";
import { Button } from "@datamole/wds-component-button";
import { Navbar as WdsNavbar } from "@datamole/wds-component-navbar";

import logoImgSrc from "@/assets/logo.png";
import styles from "@/components/Navbar/Navbar.module.css";
import { clearAuth } from "@/store/slices/auth.slice";

export const Navbar = () => {
    const { triggerLogout, user } = useAzureAdAuthentication();

    const handleLogout = () => {
        clearAuth();
        triggerLogout();
    };

    return (
        <WdsNavbar className={styles.navbar}>
            <WdsNavbar.Logo className={styles.logo}>
                <img src={logoImgSrc} alt="Fonterra" />
            </WdsNavbar.Logo>
            <WdsNavbar.Actions>
                <span className={styles.user}>{user?.username}</span>
                <WdsNavbar.Divider className={styles.divider} />
                <Button size={"sm"} variant={"outline"} onClick={handleLogout}>
                    Logout
                </Button>
            </WdsNavbar.Actions>
        </WdsNavbar>
    );
};
