import {
    AzureAdAuthenticationResult,
    AzureAdAuthenticationTemplate,
    AzureAdInteractionType,
    useAzureAdAuthentication,
} from "@datamole/wds-azure-ad-auth";
import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { config } from "@/config";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectAccessToken, setAuth } from "@/store/slices/auth.slice";

const INTERACTION_TYPE = AzureAdInteractionType.Redirect;

type Props = {
    children: ReactNode;
};

const AuthenticationGuard = ({ children }: Props) => {
    const accessToken = useAppSelector(selectAccessToken);
    const dispatch = useAppDispatch();

    const { isInteractionInProgress, acquireAccessToken } = useAzureAdAuthentication({
        interactionType: INTERACTION_TYPE,
    });

    // TODO: Implement the loading component
    const LoadingComponent = () => {
        return <div>Loading...</div>;
    };

    // TODO: Implement the error component
    const ErrorComponent = ({ error }: AzureAdAuthenticationResult) => {
        console.error(error?.errorMessage);
        return <Navigate to={"/login"} />;
    };

    useEffect(() => {
        /**
         * If user is already logged in or interaction is in progress, do nothing
         */
        if (accessToken || isInteractionInProgress) return;

        /**
         * Acquire token otherwise for the scope specified in the configuration
         * Token will be acquired silently if user is already logged in and passed to the callback function
         */
        acquireAccessToken({
            scopes: [config.AZURE_AD_AUTH_SCOPE],
            persistTokenCallback: (token) => {
                dispatch(setAuth({ accessToken: token }));
            },
        });
    }, [isInteractionInProgress, accessToken, acquireAccessToken]);

    return (
        <AzureAdAuthenticationTemplate
            interactionType={INTERACTION_TYPE}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            {children}
        </AzureAdAuthenticationTemplate>
    );
};

export { AuthenticationGuard };
