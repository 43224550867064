import { Route } from "@/routes/routes.types";

const createRoutes = <const T extends Record<string, Route>>(routes: T) => routes;

export const routes = createRoutes({
    calculation: {
        path: "/calculation",
    },
});

export type Routes = typeof routes;
