export enum TemperatureKey {
    LOW = "LOW",
    AMBIENT = "MEDIUM",
    HIGH = "HIGH",
    ULTRA_HIGH = "ULTRA_HIGH",
}

export type Temperature = {
    label: string;
    value: number;
    unit: string;
};
