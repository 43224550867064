import React from "react";
import ReactDOM from "react-dom/client";

// eslint-disable-next-line no-restricted-imports
import { version } from "./../package.json";

import { App } from "@/App";
import { config } from "@/config";

// eslint-disable-next-line no-console
console.log(`Dairy Modelling Platform - New Product Development Frontend v${version} on "${config.MODE}" environment`);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
