import { configureStore } from "@reduxjs/toolkit";

import { createSentryReduxEnhancer } from "@/services/sentry";
import { api } from "@/store/api";
import { authReducer } from "@/store/slices/auth.slice";
import { calculationReducer } from "@/store/slices/calculation.slice";
import { formulationReducer } from "@/store/slices/formulation.slice";
import { userReducer } from "@/store/slices/user.slice";

const sentryReduxEnhancer = createSentryReduxEnhancer();

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        calculation: calculationReducer,
        formulation: formulationReducer,
        user: userReducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    enhancers: (getDefaultEnhancers) => {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
