import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export * from "@/pages/pages.types";

export const CalculationPage = lazy(() => import("@/pages/CalculationPage"));

export const pageAtRouteMap = {
    calculation: CalculationPage,
} satisfies Partial<PageAtRouteMap>;
