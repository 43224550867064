import { Temperature, TemperatureKey } from "@/pages/CalculationPage/FormulationSettings/FormulationSettings.types";

export const temperatures: Record<TemperatureKey, Temperature> = {
    [TemperatureKey.LOW]: { label: "Low", value: 10, unit: "°C" },
    [TemperatureKey.AMBIENT]: { label: "Ambient", value: 25, unit: "°C" },
    [TemperatureKey.HIGH]: { label: "High", value: 80, unit: "°C" },
    [TemperatureKey.ULTRA_HIGH]: { label: "Ultra high", value: 140, unit: "°C" },
};

export const DEFAULT_TEMPERATURE_KEY = TemperatureKey.AMBIENT;
