import { PublicClientApplication } from "@azure/msal-browser";
import { AzureAdAuthenticationProvider } from "@datamole/wds-azure-ad-auth";
import { ReactNode, useEffect, useState } from "react";

import { getAzureAdInstance } from "@/utils/azure-ad-auth.utils";

type Props = {
    children: ReactNode;
};

const AuthenticationProvider = ({ children }: Props) => {
    const [azureAdInstance, setAzureAdInstance] = useState<PublicClientApplication>();

    useEffect(() => {
        getAzureAdInstance().then((instance) => {
            setAzureAdInstance(instance);
        });
    }, []);

    if (!azureAdInstance) {
        return null;
    }

    return <AzureAdAuthenticationProvider instance={azureAdInstance}>{children}</AzureAdAuthenticationProvider>;
};

export { AuthenticationProvider };
