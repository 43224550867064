import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalculationResult, CalculationStatus } from "@/types/calculation.types";

export type CalculationStoreState = {
    id: string | null;
    status: CalculationStatus | null;
    result: CalculationResult | null;
};

const INITIAL_STATE: CalculationStoreState = {
    id: null,
    status: null,
    result: null,
};

const calculationSlice = createSlice({
    name: "calculation",
    initialState: INITIAL_STATE,
    reducers: {
        startCalculation: (state, action: PayloadAction<CalculationStatus | null>) => {
            state.status = action.payload;
            state.result = null;
        },
        setCalculationId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        updateStatus: (state, action: PayloadAction<CalculationStatus | null>) => {
            state.status = action.payload;
        },
        stopCalculation: (state, action: PayloadAction<CalculationResult | null>) => {
            state.id = null;
            state.status = null;
            state.result = action.payload;
        },
        clearCalculation: (state) => {
            state.id = null;
            state.status = null;
            state.result = null;
        },
    },
});

export const { startCalculation, setCalculationId, updateStatus, stopCalculation, clearCalculation } =
    calculationSlice.actions;
export const calculationReducer = calculationSlice.reducer;
