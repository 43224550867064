import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    DEFAULT_TEMPERATURE_KEY,
    temperatures,
} from "@/pages/CalculationPage/FormulationSettings/FormulationSettings.consts";
import { Formulation, FormulationIngredient, FormulationSettings } from "@/types/formulation.types";

export type FormulationStoreState = Formulation;

const INITIAL_STATE: FormulationStoreState = {
    ingredients: [],
    settings: {
        temperature: temperatures[DEFAULT_TEMPERATURE_KEY].value,
    },
};

const formulationSlice = createSlice({
    name: "formulation",
    initialState: INITIAL_STATE,
    reducers: {
        setIngredients: (state, action: PayloadAction<FormulationIngredient[]>) => {
            state.ingredients = action.payload;
        },
        setIngredientProportion: (state, action: PayloadAction<{ id: number; proportion: number }>) => {
            const ingredientIndex = state.ingredients.findIndex((ingredient) => ingredient.id === action.payload.id);

            state.ingredients[ingredientIndex].currentProportion = action.payload.proportion;
        },
        updateSettings: (state, action: PayloadAction<FormulationSettings>) => {
            state.settings = {
                ...state.settings,
                ...action.payload,
            };
        },
    },
});

export const { setIngredients, setIngredientProportion, updateSettings } = formulationSlice.actions;
export const formulationReducer = formulationSlice.reducer;
