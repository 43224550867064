import type { PropsWithChildren } from "react";

import styles from "@/components/Layout/Layout.module.css";
import { Navbar } from "@/components/Navbar";

export const Layout = ({ children }: PropsWithChildren) => (
    <div className={styles.layout}>
        <Navbar />
        {children}
    </div>
);
