import clsx from "clsx";

import styles from "@/components/ErrorFallback/ErrorFallback.module.css";

export const ErrorFallback = () => {
    return (
        <div className={clsx(styles.error_fallback)}>
            <h1>Something went wrong :(</h1>
            <p>This issue has been automatically reported</p>
            <a href="/">Go to homepage</a>
        </div>
    );
};
