import { captureException } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { Integration } from "@sentry/types";
import { isError } from "@sentry/utils";
import { ErrorInfo, useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { config } from "@/config";

const initializeSentry = () => {
    if (!config.SENTRY_DSN) return;

    const integrations: Integration[] = [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
    ];

    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.MODE,
        release: `npd-fe-${APP_VERSION}`,
        integrations: integrations,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions. We might want to reduce this in the future.
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. We might want to reduce this in the future.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};

const extendRoutesWithSentry = Sentry.withSentryReactRouterV6Routing;

const createSentryReduxEnhancer = Sentry.createReduxEnhancer;

// Copied (and fixed types) from sentry: https://github.com/getsentry/sentry-javascript/blob/4097c4a11d3d9713d7fb085d5969c0cf7ece8c52/packages/react/src/errorboundary.tsx#L72
function setCause(error: Error, cause: Error): void {
    const seenErrors = new WeakMap<Error, boolean>();

    function recurse(error: Error, cause: Error): void {
        // If we've already seen the error, there is a recursive loop somewhere in the error's
        // cause chain. Let's just bail out then to prevent a stack overflow.
        if (seenErrors.has(error)) {
            return;
        }
        if (error.cause && isError(error.cause)) {
            seenErrors.set(error, true);
            return recurse(error.cause, cause);
        }
        error.cause = cause;
    }

    recurse(error, cause);
}

// Simplified from sentry: https://github.com/getsentry/sentry-javascript/blob/4097c4a11d3d9713d7fb085d5969c0cf7ece8c52/packages/react/src/errorboundary.tsx#L122
const reportBoundaryErrorToSentry = (error: Error, { componentStack }: ErrorInfo) => {
    // Although `componentDidCatch` is typed to accept an `Error` object, it can also be invoked
    // with non-error objects. This is why we need to check if the error is an error-like object.
    // See: https://github.com/getsentry/sentry-javascript/issues/6167
    if (isError(error)) {
        const errorBoundaryError = new Error(error.message);
        errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
        errorBoundaryError.stack = componentStack ?? undefined;

        // Using the `LinkedErrors` integration to link the errors together.
        setCause(error, errorBoundaryError);
    }

    captureException(error, {
        captureContext: {
            contexts: { react: { componentStack } },
        },
        mechanism: { handled: false },
    });
};

export { createSentryReduxEnhancer, extendRoutesWithSentry, initializeSentry, reportBoundaryErrorToSentry };
