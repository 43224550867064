import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserState = {
    name: string;
};

const userSlice = createSlice({
    name: "user",
    initialState: {} as UserState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            state.name = action.payload.name;
        },
    },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
